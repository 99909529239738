import React from "react";
import theme from "theme";
import { Theme } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"404"} />
		<Helmet>
			<title>
				Trailblaze24
			</title>
			<meta name={"description"} content={"Trailblaze24 пропонує оренду квадроциклів для тих, хто цінує свободу, екстрим та незабутні емоції."} />
			<meta property={"og:title"} content={"Trailblaze24"} />
			<meta property={"og:description"} content={"Trailblaze24 пропонує оренду квадроциклів для тих, хто цінує свободу, екстрим та незабутні емоції."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6745953f28b5e30023ae7079/images/BRP-Can-Am-Outlander-MAX-570.png?v=2024-11-27T09:00:38.548Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6745953f28b5e30023ae7079/images/BRP-Can-Am-Outlander-MAX-570.png?v=2024-11-27T09:00:38.548Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6745953f28b5e30023ae7079/images/BRP-Can-Am-Outlander-MAX-570.png?v=2024-11-27T09:00:38.548Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6745953f28b5e30023ae7079/images/BRP-Can-Am-Outlander-MAX-570.png?v=2024-11-27T09:00:38.548Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6745953f28b5e30023ae7079/images/BRP-Can-Am-Outlander-MAX-570.png?v=2024-11-27T09:00:38.548Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6745953f28b5e30023ae7079/images/BRP-Can-Am-Outlander-MAX-570.png?v=2024-11-27T09:00:38.548Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6745953f28b5e30023ae7079/images/BRP-Can-Am-Outlander-MAX-570.png?v=2024-11-27T09:00:38.548Z"} />
		</Helmet>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"673f21f141a0cf0024a5b026"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});